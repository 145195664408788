import React, { useState, useEffect, useCallback } from 'react'
import isMobile from 'is-mobile'
import {
  useMediaQuery,
} from '@mui/material'
import styles from '@assets/HomeBanner.module.scss'
import { getBannerList } from '@/api/index'
import { errorCaptured } from '@contract/utils'
import { NSWAP_SPACE } from '@utils/img'
import { imgOnError } from '@/utils/utils'
import Router from 'next/router'
import EnterSpaceModal from '@/components/EnterSpaceModal'
import { METAVERSE } from '@utils/constant'
import useConnect from '@/hooks/useConnect'
import { useAccount } from 'wagmi'

let mobile = isMobile()

const HomeBanner = () => {
  const [banner, setBanner] = useState()
  const [showMetaverseModel, setShowMetaverseModel] = useState(false)
  const isPhone = useMediaQuery('(max-width:750px)')
  const { connect } = useConnect()
  const { isConnected } = useAccount()

  const handleRedirect = useCallback(() => {
    Router.push('/launchpad')
  }, [])

  const goRaffle = useCallback(() => {
    Router.push('/raffles')
  }, [])

  const handleClick = useCallback(() => {
    const bannerData = banner[0] || {}
    const url = bannerData?.url
    if (url) {
      window.open(url)
    }
  }, [banner])

  const newData = useCallback(async () => {
    const [err, res] = await errorCaptured(getBannerList)
    if (err) {
      console.log(err, 'getBanner error')
      setBanner([])
      return
    }
    if (res) {
      if (res?.code === 200) {
        setBanner(res?.data || [])
      }
    }
  }, [])

  const checkConnect = async() => {
    if (!isConnected) {
      connect()
      return
    }

    const address = localStorage.getItem('address')
    if (isConnected || address) {
      setShowMetaverseModel(true)
    }
  }

  useEffect(() => {
    newData()
  }, [])

  if (!banner || (banner && banner.length <= 0)) return <div className={styles.loading_img} />

  if (banner && banner.length > 0) {
    const bannerData = banner[0] || {}
    const bannerUrl = isPhone ? bannerData?.mobile_banner : bannerData?.web_banner
    // 取消轮播
    // return <Swiper data = {banner} />
    return (
      <div className={styles.main}>
        <div className={styles.homeHeadContainer}>
          <div className={styles.homeHeadLeft}>
            <div className={styles.homeHeadTitle}>
              Experience, access, and own NFTs
            </div>
            <div className={styles.homeHeadDesc}>
              Nswap is an all-in-one NFT marketplace, launchpad, and raffle generator
            </div>
            <div className={styles.homeHeadBtnBox}>
              <div className={styles.leftBtn} onClick={handleRedirect}>Launchpad</div>
              <div className={styles.rightBtn} onClick={goRaffle}>Lucky
                <i className={styles.homepage_raffle_icon} />
              </div>
            </div>
            {mobile ? null : (
              <div onClick={checkConnect} className={styles.ipollo} >
                <img src={NSWAP_SPACE} width={412} height={70} className={styles.ipolloImage}/>
              </div>
            )}
          </div>
          {mobile ? (
            <div onClick={checkConnect} className={styles.ipollo} >
              <img src={NSWAP_SPACE} width={412} height={70} className={styles.ipolloImage}/>
            </div>
          ) : null }
          <div
            className={styles.homeHeadRight}
            onClick={handleClick}
          >
            <img src={bannerUrl} alt="" onError={imgOnError} />
          </div>
        </div>

        {bannerUrl ? (
          <div className={styles.maskMain}>
            <img src={bannerUrl} alt="" />
          </div>
        ) : ''}

        {showMetaverseModel && (
          <EnterSpaceModal
            spaceName={METAVERSE} open={showMetaverseModel} setOpen={setShowMetaverseModel}
          />
        )}
      </div>
    )
  }
}

export default React.memo(HomeBanner)