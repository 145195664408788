import React, { useState, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getHotList } from '@pages/api'
import { errorCaptured } from '@contract/utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'

import Card from './Card'
import styles from './index.module.scss'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

function Hot() {
  const [list, setList] = useState([])
  const match750 = useMediaQuery('(max-width: 750px)')

  const initHotList = async() => {
    const [err, res] = await errorCaptured(getHotList)
    if (err) {
      console.log(console.log(err, 'error getHotList'))
    }
    if (res) {
      const { data } = res || {}
      const { result } = data || []
      setList(result)
    }
  }
  useEffect(() => {
    initHotList()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.services}>
        {list.length && match750
          ? (
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              loop={true}
              modules={[Autoplay, Pagination]}
              className={styles.mySwiper}
            >
              {list.map((item, index) => (
                <SwiperSlide key={index}>
                  <Card card={item} key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null
        }
        {!match750 && list.length > 3 ? (
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            className={styles.mySwiper}
          >
            {list.map((item, index) => {
              return (
                <SwiperSlide className={styles.swiper_slide} key={index}>
                  <Card card={item} key={item.url} />
                </SwiperSlide>
              ) })}
          </Swiper>
        ) : null}

        {!match750 && list.length && list.length <= 3 ? (
          <div className={styles.hot_list_item}>
            {list.map((_item, _index) => {
              return <Card card={_item} key={_item.url} />
            })}
          </div>
        ) : null}

      </div>
    </div>
  )
}

export default Hot
