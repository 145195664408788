import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Modal } from '@mui/material'
import { CLOSE_MODAL, ITEM_DEFAULT_URI, SPACE_ICON } from '@utils/img'
import { getETHWItemList, getETHItemList } from '@/api/index'

import styles from '@/assets/Ipollo.module.scss'

const SPACE_INFO_MAP = {
  metatwit: {
    title: 'Enter Metatwit',
    prdfixUrl: 'http://metatwit.io/space/twit/',
  },
  metaverse: {
    title: 'Enter Metaverse',
    prdfixUrl: 'http://nswap.space/space/nswap/',
  },
}

function EnterSpaceModal({ open, setOpen, spaceName }) {
  const [name, setName] = useState('Unnamed')
  const [image, setImage] = useState(undefined)
  const [ethList, setETH] = useState([])
  const [ethwList, setETHW] = useState([])
  const [list, setList] = useState([])

  const currentSpace = useMemo(() => {
    return SPACE_INFO_MAP[spaceName] || SPACE_INFO_MAP['metaverse']
  }, [spaceName])

  const handleClick = useCallback(() => {
    const nft = window.btoa(image)
    window.open(`${currentSpace.prdfixUrl}${name}?nft=${nft}`)
    setOpen(false)
  }, [currentSpace])

  const handleImageClick = (oss_uri) => {
    setImage(oss_uri)
  }

  const handleError = (e) => {
    e.currentTarget.onerror = null
    e.currentTarget.src = ITEM_DEFAULT_URI
  }

  const handleChange = (e) => {
    const { target } = e || {}
    const { value } = target || {}
    setName(value)
  }

  const getETHItem = async (param) => {
    const res = await getETHItemList(param)
    setETH(res?.data?.result ? res?.data?.result : [])
  }

  const getETHWItem = async (param) => {
    const res = await getETHWItemList(param)
    setETHW(res?.data?.result ? res?.data?.result : [])
  }

  useEffect(() => {
    if (open) {
      const address = localStorage.getItem('address')
      const param = { 'page': 1, 'page_size': 40, 'filter': { 'params': { 'owner': address, 'collection_ids': [], 'status': [], 'chain': 1 }}, 'sort': 1 }
      getETHItem(param)
      getETHWItem(param)
    }
  }, [open])

  useEffect(() => {
    const list = [...ethList, ...ethwList]
    const currentImg = list[0]?.oss_uri || SPACE_ICON
    setImage(currentImg)
    setList(list)
  }, [ethList, ethwList])

  return (
    <Modal open={open}>
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className={styles.shareModal}>
          <div className={styles.closeImage} onClick={() => setOpen(false)}>
            <img src={CLOSE_MODAL}width={16} height={16} />
          </div>
          <div className={styles.modalTitle}>{currentSpace.title}</div>
          <div className={styles.con}>
            <div className={styles.title}>Name</div>
            <input className={styles.input} placeholder="Enter name" value={name} onChange={handleChange}/>
            <div className={styles.title}>Avatar</div>
            <div className={styles.avatarBox}>
              <div className={styles.avatar}>
                <img onError={handleError} src={image} className={styles.avatar}/>
              </div>
              <div className={styles.avatarImageBox}>
                {Array.isArray(list) && list.length ? list.map((item, index) => {
                  const { oss_uri } = item || {}
                  return (
                    <div key={index} className={styles.image} onClick={() => handleImageClick(oss_uri)}>
                      <img onError={handleError} src={oss_uri} className={styles.image} />
                    </div>
                  )
                }) : <div className={styles.noItem}>No item to display</div>}
              </div>
            </div>
            <div className={styles.confirm} onClick={handleClick}>Confirm</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default EnterSpaceModal
