import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Router from 'next/router'
import { Button } from '@mui/material'
import { checkCollectionImported, checkCollectionImportedBSC } from '@/api'
import { getRankingsETH, getRankingsETHW, getRankingsBSC } from '@/api/rankings'
import { errorCaptured } from '@contract/utils'
import { showSnackBar } from '@/components/CustomizedSnackbars'
import Select from '@/components/Select'
import { DEFAULT_COLLECTION } from '@utils/img'
import { numFormatter } from '@utils/utils'
import { imgOnError } from '@/utils/utils'
import Loading from '@components/Loading'
import Show from '@/components/Show'
import { useSelector } from 'react-redux'

import config from '@/config/config'
import styles from '@/assets/Home.module.scss'

const logoUrl = `${config.assetsUrl}0.2.3/`

import { getChainInfo } from '@utils/networks.js'

const topCollectionFilter = [
  {
    key: 1,
    label: 'last 7 days',
    value: '7d',
  },
  {
    key: 2,
    label: 'last 24 hours',
    value: '1d',
  },
]

const defaultPage = {
  limit: 9,
  offset: 0,
  sort_direction: 'desc',
  sort_by: 'volume',
}

const TopCollection = () => {
  const { visitChain: {
    chainId,
    chainName,
  }} = useSelector(state => state.chain)
  const [loading, setLoading] = useState(false)
  const [pageInfo, setPageInfo] = useState(defaultPage)
  const [rankings, setRankings] = useState([])
  const [filterRange, setFilterRange] = useState('1d')
  const [type, setType] = useState('1')

  useEffect(async () => {
    let fn
    if (chainId === 1) {
      fn = getRankingsETH
    } else if (chainId === 56) {
      fn = getRankingsBSC
    } else if (chainId === 10001) {
      fn = getRankingsETHW
    } else if (chainId === 101) {
      return
    }

    setType(chainId)
    setLoading(true)
    const [err, res] = await errorCaptured(fn, { range: filterRange, ...pageInfo })
    setLoading(false)
    if (err) {
      console.log(err, 'rankings error')
      setRankings([])
      return
    }
    if (res) {
      setRankings(res && res.data ? res.data?.result || [] : [])
    }
  }, [pageInfo, chainId])

  const handleCheckFilter = useCallback(({ value }) => {
    setFilterRange(value)
    setPageInfo({
      ...pageInfo,
      range: value,
    })
  }, [pageInfo])

  const goCollectionDetail = useCallback(async (item) => {
    const _chainName = chainName?.toLocaleLowerCase()
    if (type === '1') {
      if (item?.address) {
        const res = await checkCollectionImported({ contract_address: item?.address })
        if (res && res.data) {
          Router.push(`/collection/${_chainName}/${item?.address}`)
          return
        }
        showSnackBar({ type: 'error', message: 'No record yet' })
        return
      }
      return
    }

    if (type === '56') {
      const res = await checkCollectionImportedBSC({ contract_address: item?.address })
      if (res && res.data) {
        Router.push(`/collection/${_chainName}/${address}`)
        return
      }
      showSnackBar({ type: 'error', message: 'No record yet' })
      return
    }

    Router.push(`/collection/${_chainName}/${item?.address || ''}`)
  }, [type, chainName])

  const filterText = useMemo(() => {
    const item = topCollectionFilter.find(item => item.value === filterRange)
    return item?.label
  }, [filterRange])

  const selectHeader = useMemo(() => {
    return (
      <span className={styles.filter_text_title}>
        {filterText}
        <img src={`${logoUrl}down.png`} width={20} height={20} alt="" />
      </span>
    )
  }, [filterText])

  if (chainId === 101) {
    return null
  }

  return (
    <div className={styles.collection_container}>
      <div className={styles.title_container}>
        <div className={styles.top_title}>
          <span className={styles.top_title_text}>Top collections over</span>
          <Select
            header={selectHeader}
            options={topCollectionFilter}
            defaultValue={24}
            onChange={handleCheckFilter}
          />
        </div>
      </div>
      <div className={styles.rankingBox}>
        <Show test={loading}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Loading
              boxStyle={{ width: '24px', height: '24px' }} circleStyle={{ background: '#5856d6' }}
            />
          </div>
        </Show>
        <Show test={!loading}>
          {
            rankings?.map((item, index) => {
              const { oss_uri, name, volume, change, floor_price } = item || {}
              return (
                <div key={name} className={styles.rankItem} onClick={() => goCollectionDetail(item)}>
                  <div className={styles.itemNum}>{index + 1}</div>
                  <img className={styles.item_img} src={oss_uri || DEFAULT_COLLECTION} width="48" height="48" alt="" onError={imgOnError} />
                  <div className={styles.itemRight}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className={styles.itemName}>{name}</div>
                      {change === '-'
                        ? (
                          <div style={{ color: '#FF3B30', fontSize: 14, fontWeight: '500' }}>
                            0%
                          </div>
                        )
                        : (
                          <div style={{ color: change > 0 ? '#34C759' : '#FF3B30', fontSize: 14, fontWeight: '500' }}>
                            {change > 0 ? '+' : ''}{numFormatter(Number(change * 100)) }%
                          </div>
                        )
                      }
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className={styles.floorPrice}>
                        Floor price:
                        <>
                          <div className={styles.ethBox}>
                            <img width={16} height={16} src={getChainInfo(type)?.logo}/>
                          </div>
                          <span className={styles.price}>{floor_price.toLocaleString()}</span>
                        </>
                      </div>
                      <div className={styles.price}>
                        <div className={styles.ethBox}>
                          <img width={16} height={16} src={getChainInfo(type)?.logo}/>
                        </div>{volume.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Show>

      </div>
      <div className={styles.view_all}>
        <Button
          variant="contained"
          className={styles.view_all_btn}
          onClick={() => { Router.push('/rankings') }}
        >
          Go to Rankings
        </Button>
      </div>

    </div>
  )
}

export default TopCollection
