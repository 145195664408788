import React from 'react'
import Layouts from '@/layouts/Layouts.js'
import HomePage from '@/components/homePage'
import { usePhone } from '@utils/utils'
import HomePageView from '@views/HomePage'
function Index() {
  return <HomePageView />
}

Index.layout = Layouts

export default Index
