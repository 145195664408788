import React, { useState, useEffect, useCallback } from 'react'
import Image from 'next/image'
import { providers } from 'ethers'
import { useRouter } from 'next/router'
import isMobile from 'is-mobile'

import Upcoming from '@components/homePage/Upcoming'
import Hot from '@components/Hot/index'
import TopCollection from '@components/homePage/TopCollection'
import TrendingNFT from '@components/homePage/TrendingNFT'
import HomeBanner from '@components/homePage/HomeBanner.jsx'
import HomeResources from '@components/homePage/HomeResources.jsx'
import Exp from '@components/Exp'
import { showSnackBar } from '@/components/CustomizedSnackbars'

import Dialog from '@mui/material/Dialog'
import { CLOSE_MODAL } from '@utils/img'
import { getIsNewUser, createInviteUser, login } from '@/api/index'
import { setItem, getSessionItem } from '@/utils/utils'
import styles from '@/assets/Home.module.scss'
import { useAccount } from 'wagmi'

const textObj = {
  'invited': 'Nswap is an all-in-one NFT marketplace, launchpad, and raffle generator',
  'new': 'You have finished bind invitation relationship.',
  'old': 'This is an old accout which has already logged in on Nswap, doesn\'t meet the invitation criteria.',
}

function HomePage() {
  const [inviteOpen, setInviteOpen] = useState(false)
  const [isNewUser, setIsNewUser] = useState('invited')
  const [invite_name, setInviteName] = useState('')
  const { address } = useAccount()

  const history = useRouter()

  useEffect(() => {
    if (isMobile()) {
      // twiter and discord auth
      const query = new URLSearchParams(location.search)
      const auth_source_pathname = getSessionItem('auth_source_pathname')
      const oauth_token = query.get('oauth_token')
      const oauth_verify = query.get('oauth_verifier')
      const code = query.get('code')

      if (auth_source_pathname && (code || (oauth_token && oauth_verify))) {
        history.push(`${auth_source_pathname}${window.location.search}`)
      }
    }
  }, [])

  useEffect(() => {
    if (isMobile()) return
    const query = new URLSearchParams(location.search)
    const hasInvite = query.get('invite') || ''

    if (hasInvite) {
      setInviteOpen(true)
      setInviteName(hasInvite)
    }
  }, [])

  const handleClose = () => {
    if (['new', 'old'].includes(isNewUser)) {
      const url = window.location.origin
      window.location.replace(url)
    }
    setInviteOpen(false)
  }

  const handleConfirm = useCallback(async () => {
    if (isMobile()) return
    if (['old', 'new'].includes(isNewUser)) {
      setInviteOpen(false)
      const url = window.location.origin
      window.location.replace(url)
      return
    }
    setInviteOpen(false)

    // const [err, provider] = await errorCaptured(web3Modal.connect)

    if (err) {
      return
    }

    setInviteOpen(true)

    if (address === invite_name) {
      showSnackBar({ type: 'error', message: 'Not allowed to invite yourself!' })
      return
    }
    // 如果是新用户才去调login接口，因为只要调了login接口就会变成老用户了
    const res = await getIsNewUser({ address, until: 600 })
    console.log(res, 'res---')

    if (res?.code === 200) {
      const isOld = res?.data?.has_registered
      let isUser = isOld ? 'old' : 'new'
      setIsNewUser(isUser)
      if (!isOld) {
        // 新用户入库
        const result = await login({ address })
        let date = new Date()
        date = date.setDate(date.getDate() + 1)
        const dateTimeStr = new Date(date).getTime()
        let userInfo = result?.data || {}
        userInfo.expiresTime = dateTimeStr
        setItem('userInfo', userInfo)
        await createInviteUser({
          inviter: invite_name,
        })
      }
    }
  }, [isNewUser, invite_name])

  const getTitle = () => {
    if (isNewUser === 'invited') {
      return <div><span style={{ color: '#5200FF' }}>{invite_name.slice(0, 6)} </span>invites you to join Nswap</div>
    } else if (isNewUser === 'new') {
      return 'Congratulation'
    } else if (isNewUser === 'old') {
      return 'Not eligible for binding'
    }
  }

  return (
    <>
      <Exp page="index"/>
      <HomeBanner />
      <Hot />
      <Upcoming/>
      <TopCollection />
      <TrendingNFT />
      <HomeResources />

      {!isMobile() ? (
        <Dialog open={inviteOpen}
          sx={{
            '& .MuiPaper-root': {
              width: '700px',
              maxWidth: '700px',
            },
          }}
        >
          <div className={styles.inviteCon}>
            <div className={styles.invite_header}>
              <div className={styles.invite_title}>{getTitle()}</div>
              <div className={styles.invite_close} onClick={handleClose}>
                <Image src={CLOSE_MODAL} width={12} height={12} alt="closeImg"/>
              </div>
            </div>
            <div className={styles.invite_content}>
              {isNewUser === 'invited' && (
                <div className={styles.invite_subTitle}>
                  Experience, access, and own NFTs
                </div>
              )}
              <div className={styles.invite_desc}>
                {textObj[isNewUser]}
              </div>

              <div className={styles.invite_btn} onClick={handleConfirm}>
                Confirm
              </div>
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  )
}

export default HomePage
